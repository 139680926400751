import React, { useEffect, useState } from 'react';

import {
  ExclamationCircleOutlined,
  FileDoneOutlined,
  CloseCircleOutlined,
  DollarCircleOutlined,
} from '@ant-design/icons/lib/icons';
import {
  Button,
  Row,
  Col,
  Card,
  Space,
  Table,
  TablePaginationConfig,
  Tag,
  Modal,
  Input,
  Select,
  Empty,
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { FilterValue } from 'antd/lib/table/interface';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import ClientAssetsContractsTable from './ClientAssetContractsTable';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { AssetType } from '../../../models/AssetType';
import { ContractType } from '../../../models/ContractType';
import AssetPendingTasks from '../../AssetsPendingTasks/AssetsPendingTasks';

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Record<string, FilterValue>;
}

interface tableDataProps {
  columnParams?: ColumnsType<AssetType>;
  tableTitle?: string;
  tableAction: (actionRoute: string, actionID: string) => void;
  isCustomer?: boolean;
  tableFilter?: string;
  tableData?: AssetType[];
  viewCustomer?: boolean;
}

const AssetTable: React.FC<tableDataProps> = ({
  columnParams,
  tableTitle,
  tableAction,
  viewCustomer,
  isCustomer,
  tableFilter,
  tableData,
}) => {
  const [loadingTable, setLoadingTable] = useState(false);
  const [data, setData] = useState<AssetType[]>();
  const [openContractsModal, setOpenContractsModal] = useState(false);
  const [openActionModal, setOpenActionModal] = useState(false);
  const [assetContracts, setAssetContracts] = useState<ContractType[]>([]);
  const [assetId, setAssetId] = useState('');
  const { Search } = Input;
  const [searchField, setSearchField] = useState('Select Field');
  const [openApprovalModal, setOpenApprovalModal] = useState(false);
  const { Option } = Select;
  const [asset, setAsset] = useState({ ID: '', serial: '' });
  const [singleAsset, setSingleAsset] = useState<AssetType>({} as AssetType);
  const [searchFieldSub, setSearchFieldSub] = useState('Select Field');
  const [isSubField, setIsSubField] = useState(false);
  const [selectSearchTerms, setSelectSearchTerms] = useState<string>('');
  const [disableSearch, setDisableSearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [actionAssetID, setActionAssetID] = useState({
    AssetId: '',
    customerId: '',
  });

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [filters, setFilters] = useState('');
  const [dropdownItems, setDropdownItems] = useState([
    { id: 'Initialized', value: 'Active' },
    // { id: 'PendingApproval', value: 'Pending Approval' },
    { id: 'Updated', value: 'Updated' },
  ]);

  const searchFields = [
    { id: 'actionRequired=true', value: 'Action' },
    { id: 'autoUpdated=true', value: 'Auto Updated' },
    { id: 'itemInstance', value: 'Item Instance' },
    { id: 'productId', value: 'Product ID' },
    { id: 'serialNbr', value: 'Serial Number' },
    { id: 'customerNbr', value: 'Customer Number' },
    { id: 'contractNbr', value: 'Contract Number' },
    { id: 'status', value: 'Status' },
  ];

  const openViewContracts = (contractData: [ContractType], id: string) => {
    setAssetContracts(contractData);
    setAssetId(id);
    setOpenContractsModal(true);
  };

  const getAssetApproval = async (
    ID: string,
    serial: string,
    customerID: string
  ) => {
    setAsset({ ID, serial });
    setOpenApprovalModal(true);
    setActionAssetID({ AssetId: ID, customerId: customerID });
  };

  const getAssetActions = async (
    ID: string,
    serial: string,
    customerID: string
  ) => {
    axiosPrivate({
      method: 'GET',
      url: `/assets/${ID}`,
    })
      .then((response) => {
        setSingleAsset(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    setActionAssetID({ AssetId: ID, customerId: customerID });
    setOpenActionModal(true);
  };

  const columns: ColumnsType<AssetType> = [
    {
      title: `Action`,
      key: 'autoUpdated',
      dataIndex: 'autoUpdated',
      render: (_, record: AssetType) => {
        const { actionRequired, autoUpdated } = record;
        if (actionRequired && autoUpdated) {
          return (
            <>
              <Tag
                color="orange"
                key={record.id}
                className="asset-approval-link"
                onClick={() =>
                  getAssetActions(
                    record.id!,
                    record.serialNbr!,
                    record.customer.id!
                  )
                }
              >
                Action Required <ExclamationCircleOutlined />
              </Tag>
              <Tag color="blue" key={record.id} className="asset-approval-link">
                Auto Updates
              </Tag>
            </>
          );
        }

        if (autoUpdated) {
          return (
            <Tag color="blue" key={record.id} className="asset-approval-link">
              Auto Updates
            </Tag>
          );
        }

        if (actionRequired) {
          return (
            <>
              <Tag
                color="volcano"
                key={record.id}
                className="asset-approval-link"
                onClick={() =>
                  getAssetActions(
                    record.id!,
                    record.serialNbr!,
                    record.customer.id!
                  )
                }
              >
                Action Required <ExclamationCircleOutlined />
              </Tag>
            </>
          );
        }
        return (
          <Tag color="default" key={record.id}>
            -
          </Tag>
        );
      },
    },
    {
      title: 'Item Instance',
      dataIndex: 'itemInstance',
      key: 'itemInstance',
    },
    {
      title: 'Product ID',
      dataIndex: 'productId',
      key: 'productId',
    },
    {
      title: 'Serial Number',
      dataIndex: 'serialNbr',
      key: 'serialNbr',
    },
    {
      title: 'Contract',
      dataIndex: 'contract',
      key: 'contract',
      render: (text, record) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
        if (record.contracts?.length! >= 1) {
          return (
            <Button
              type="primary"
              size="small"
              onClick={() => openViewContracts(record.contracts!, record.id!)}
            >
              View Contracts
            </Button>
          );
        }
        if (record.requireContract === true) {
          return (
            <Tag color="volcano" key={record.id}>
              Need new contract
            </Tag>
          );
        }
        return (
          <Tag color="volcano" key={record.id}>
            No Contract
          </Tag>
        );
      },
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (text, record: AssetType) => {
        let color = 'purple';
        text = 'Status Not recongnized';
        if (record.status === 'Initialized') {
          color = 'green';
          text = 'Active';
        }
        if (record.status === 'Updated') {
          color = 'green';
          text = 'Active';
        }
        // if (record.status === 'PendingApproval') {
        //   color = 'yellow';
        //   text = 'Pending Approval';
        //   return (
        //     <Tag
        //       color={color}
        //       className="asset-approval-link"
        //       key={record.status}
        //       onClick={() =>
        //         getAssetApproval(
        //           record.id!,
        //           record.serialNbr!,
        //           record.customer.id!
        //         )
        //       }
        //     >
        //       {text} <FileDoneOutlined />
        //     </Tag>
        //   );
        // }
        return (
          <Tag color={color} key={record.status}>
            {text}
          </Tag>
        );
      },
    },
    {
      title: '',
      key: 'action',
      render: (_, record: AssetType) => {
        if (isCustomer || viewCustomer) {
          return (
            <Space size="middle">
              <Button
                size="small"
                type="primary"
                onClick={() => tableAction(record.id!, record.customer.id!)}
              >
                View
              </Button>
            </Space>
          );
        }
        return (
          <Space size="middle">
            <Button
              size="small"
              type="primary"
              onClick={() => navigate(`/ncr/assets/${record.id}`)}
            >
              View
            </Button>
          </Space>
        );
      },
    },
  ];

  const setSearchTerms = (value: string) => {
    setSearchFieldSub(value);
    if (searchField === 'status') {
      setFilters(`${searchField}=${value}`);
    } else {
      setFilters(``);
    }
  };

  const setMainSearchTerms = (value: string) => {
    if (value === 'actionRequired=true' || value === 'autoUpdated=true') {
      setFilters(value);
    }
    setSearchField(value);
    setSelectSearchTerms(value);
    setIsSubField(false);
    setSearchFieldSub('');
  };

  useEffect(() => {
    if (
      searchField === 'status' ||
      searchField === 'actionRequired=true' ||
      searchField === 'autoUpdated=true'
    ) {
      setIsSubField(true);
      setSearchFieldSub('Select Field');
      setDisableSearch(true);
      setSearchValue('');
      console.log(searchField, 'searchField');
    } else {
      setIsSubField(false);
      setSearchFieldSub('Select Field');
      setSelectSearchTerms('');
      setDisableSearch(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchField]);

  const { data: assets, isLoading } = useQuery(
    ['clientAssets', page, size, filters, tableFilter],
    () =>
      axiosPrivate({
        method: 'GET',
        url: `/assets?customerId=${sessionStorage.getItem('customer')}&${
          filters || ''
        }`,
        params: {
          page,
          size,
        },
      })
  );
  const handleChange = (pagination: any) => {
    setPage(pagination.current - 1);
    setSize(pagination.pageSize);
  };

  const handleSearch = (e: any) => {
    setPage(0);
    setSize(10);
    const encodedValue = encodeURIComponent(e.trim());
    setFilters(`${searchField}=${encodedValue}`);
  };

  const getAssetView = () => {
    if (viewCustomer) {
      sessionStorage.setItem('customer', actionAssetID.customerId);
      navigate(
        `/ncr/customers/${actionAssetID.customerId}/assets/${actionAssetID.AssetId}`
      );
    } else {
      navigate(`${actionAssetID.AssetId}`);
    }
  };

  const selectBefore = (
    <div>
      <Select
        defaultValue="Select Field"
        placeholder="Select Field"
        value={searchField}
        onChange={(e) => setMainSearchTerms(e)}
        style={{ width: 200 }}
        getPopupContainer={(trigger) => trigger.parentElement}
      >
        {searchFields?.map((item) => {
          return (
            <Option key={item.id} value={item.id}>
              {item.value}
            </Option>
          );
        })}
      </Select>
      {searchField === 'status' && (
        <>
          <Select
            defaultValue="Select Field"
            placeholder="Select Field"
            value={searchFieldSub}
            onChange={(e) => setSearchTerms(e)}
            style={{ width: 200 }}
            getPopupContainer={(trigger) => trigger.parentElement}
          >
            {dropdownItems?.map((item) => {
              return (
                <Option key={item.id} value={item.id}>
                  {item.value}
                </Option>
              );
            })}
          </Select>
        </>
      )}
      {searchField === 'status' ||
      searchField === 'autoUpdated=true' ||
      searchField === 'actionRequired=true' ? (
        <Button
          type="primary"
          size="small"
          className="clear-button"
          onClick={() => {
            setSearchValue('');
            setFilters('');
            setSearchField('Select Field');
            setSearchFieldSub('Select Field');
            setDisableSearch(false);
            setPage(0);
            setSize(10);
          }}
        >
          Clear Filter
          <CloseCircleOutlined />
        </Button>
      ) : null}
    </div>
  );

  return (
    <Row>
      <Col span={24}>
        <Card title={tableTitle !== '' ? tableTitle : ''} className="h-full">
          <div className="search-bar">
            <Search
              addonBefore={selectBefore}
              placeholder="Search"
              enterButton="Search"
              className={`${disableSearch ? 'disabled' : ''}`}
              disabled={disableSearch}
              size="large"
              allowClear
              onSearch={(e) => handleSearch(e)}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </div>
          <br />
          <Table
            columns={columnParams || columns}
            rowKey={(record) => record.id!}
            dataSource={assets?.data}
            loading={isLoading}
            onChange={handleChange}
            locale={{
              emptyText: <Empty description="No assets" />,
            }}
            pagination={{
              defaultPageSize: 10,
              current: page + 1,
              pageSize: size,
              total: Number(assets?.headers['x-total-count']),
              pageSizeOptions: ['10', '20', '50', '100'],
              showSizeChanger: true,
              hideOnSinglePage:
                Number(assets?.headers['x-total-count']) <= size,
            }}
          />
        </Card>
      </Col>

      {/* <Modal */}
      {/*  title={`Asset: Serial Number ${asset?.serial}`} */}
      {/*  open={openApprovalModal} */}
      {/*  onCancel={() => setOpenApprovalModal(false)} */}
      {/*  width="80%" */}
      {/*  footer={[ */}
      {/*    <> */}
      {/*      <Button */}
      {/*        key="back" */}
      {/*        size="large" */}
      {/*        onClick={() => setOpenApprovalModal(false)} */}
      {/*      > */}
      {/*        Close */}
      {/*      </Button> */}

      {/*      <Button onClick={() => getAssetView()} size="large" type="primary"> */}
      {/*        View */}
      {/*      </Button> */}
      {/*    </>, */}
      {/*  ]} */}
      {/* > */}
      {/*  <AssetPendingTasks assetId={asset.ID!} /> */}
      {/* </Modal> */}
      <Modal
        title="Asset Contracts"
        open={openContractsModal}
        onCancel={() => setOpenContractsModal(false)}
        width="80%"
        footer={[
          <Button
            key="back"
            size="large"
            onClick={() => setOpenContractsModal(false)}
          >
            Close
          </Button>,
        ]}
      >
        <ClientAssetsContractsTable
          assetId={assetId}
          tableData={assetContracts}
          isCustomer={isCustomer}
          searchFields={[
            { id: 'status', value: 'Status' },
            { id: 'customerNbr', value: 'Customer Number' },
          ]}
        />
      </Modal>
    </Row>
  );
};

export default AssetTable;
