/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import React, { useEffect, useState } from 'react';

import {
  DollarCircleOutlined,
  FileDoneOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  StopOutlined,
  WarningOutlined,
} from '@ant-design/icons/lib/icons';
import {
  Button,
  Row,
  Col,
  Card,
  Space,
  Table,
  TablePaginationConfig,
  Tag,
  Modal,
  Input,
  Select,
  Empty,
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { FilterValue } from 'antd/lib/table/interface';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import NCRAssetsContractsTable from './NCRAssetContractsTable';
import AssetService from '../../../APIServices/AssetService';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { AssetType } from '../../../models/AssetType';
import { ContractType } from '../../../models/ContractType';
import { convertDate } from '../../../utils/ConvertDate';
import AssetActions from '../../AssetActions/AssetActions';
import AssetPendingTasks from '../../AssetsPendingTasks/AssetsPendingTasks';

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Record<string, FilterValue>;
}

interface tableDataProps {
  columnParams?: ColumnsType<AssetType>;
  tableTitle?: string;
  tableAction: (actionRoute: string, actionID: string) => void;
  isCustomer?: boolean;
  tableFilter?: string;
  tableData?: AssetType[];
  viewCustomer?: boolean;
}

const AssetTable: React.FC<tableDataProps> = ({
  columnParams,
  tableTitle,
  tableAction,
  viewCustomer,
  isCustomer,
  tableFilter,
  tableData,
}) => {
  const [loadingTable, setLoadingTable] = useState(false);
  const [data, setData] = useState<AssetType[]>();
  const [openContractsModal, setOpenContractsModal] = useState(false);
  const [openActionModal, setOpenActionModal] = useState(false);
  const [assetContracts, setAssetContracts] = useState<ContractType[]>([]);
  const [assetId, setAssetId] = useState('');
  const { Search } = Input;
  const [searchField, setSearchField] = useState('Select Field');
  const [openApprovalModal, setOpenApprovalModal] = useState(false);
  const { Option } = Select;
  const [singleAsset, setSingleAsset] = useState<AssetType>({} as AssetType);
  const [searchFieldSub, setSearchFieldSub] = useState('Select Field');
  const [isSubField, setIsSubField] = useState(false);
  const [selectSearchTerms, setSelectSearchTerms] = useState<string>('');
  const [disableSearch, setDisableSearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const clientId = sessionStorage.getItem('customer');
  const [singleAssetLoading, setSingleAssetLoading] = useState(false);
  const [actionAssetID, setActionAssetID] = useState({
    AssetId: '',
    customerId: '',
  });

  const queryClient = useQueryClient();

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [filters, setFilters] = useState('');
  const [contractCustomer, setContractCustomer] = useState('');
  const [dropdownItems, setDropdownItems] = useState([
    { id: 'Initialized', value: 'Initialized' },
    // { id: 'PendingApproval', value: 'Pending Approval' },
    { id: 'Updated', value: 'Updated' },
  ]);

  const searchFields = [
    { id: 'requireContract=true', value: 'Require New Contract' },
    { id: 'actionRequired=true', value: 'Action Required' },
    { id: 'autoUpdated=true', value: 'Auto Updated' },
    { id: 'itemInstance', value: 'Item Instance' },
    { id: 'productId', value: 'Product ID' },
    { id: 'serialNbr', value: 'Serial Number' },
    { id: 'customerNbr', value: 'Customer Number' },
    { id: 'contractNbr', value: 'Contract Number' },
    { id: 'status', value: 'Status' },
  ];

  const openViewContracts = (
    contractData: [ContractType],
    id: string,
    customerId: string
  ) => {
    setAssetContracts(contractData);
    setAssetId(id);
    setContractCustomer(customerId);
    setOpenContractsModal(true);
  };

  const closeContractsModal = () => {
    setAssetContracts([]);
    setAssetId('');
    setContractCustomer('');
    setOpenContractsModal(false);
  };

  const getAssetApproval = async (
    ID: string,
    serial: string,
    customerID: string
  ) => {
    setOpenApprovalModal(true);
    setActionAssetID({ AssetId: ID, customerId: customerID });
  };

  const getAssetActions = async (
    ID: string,
    serial: string,
    customerID: string
  ) => {
    setSingleAssetLoading(true);
    axiosPrivate({
      method: 'GET',
      url: `/assets/${ID}`,
    })
      .then((response) => {
        setSingleAsset(response.data);
        setSingleAssetLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
    setActionAssetID({ AssetId: ID, customerId: customerID });
    setOpenActionModal(true);
  };

  const viewSingleSite = (customer: string, site: string) => {
    sessionStorage.setItem('customer', customer);
    navigate(`/ncr/customers/${customer}/sites/${site}`);
  };

  const columns: ColumnsType<AssetType> = [
    {
      title: `Action`,
      key: 'autoUpdated',
      dataIndex: 'autoUpdated',
      render: (_, record: AssetType) => {
        const { actionRequired, autoUpdated } = record;

        if (actionRequired && autoUpdated) {
          return (
            <>
              <Tag
                color="orange"
                key={record.id}
                className="asset-approval-link"
                onClick={() =>
                  getAssetActions(
                    record?.id!,
                    record?.serialNbr!,
                    record?.customer?.id!
                  )
                }
              >
                Action Required <ExclamationCircleOutlined />
              </Tag>
              <Tag color="blue">Auto Updates</Tag>
            </>
          );
        }

        if (autoUpdated) {
          return <Tag color="blue">Auto Updates</Tag>;
        }

        if (actionRequired) {
          return (
            <>
              <Tag
                color="volcano"
                key={record.id}
                className="asset-approval-link"
                onClick={() =>
                  getAssetActions(
                    record?.id!,
                    record?.serialNbr!,
                    record?.customer?.id!
                  )
                }
              >
                Action Required <ExclamationCircleOutlined />
              </Tag>
            </>
          );
        }

        return <Tag color="default">-</Tag>;
      },
    },
    {
      title: 'Item Instance',
      dataIndex: 'itemInstance',
      key: 'itemInstance',
    },
    {
      title: 'Product ID',
      dataIndex: 'productId',
      key: 'productId',
    },
    {
      title: 'Serial Num',
      dataIndex: 'serialNbr',
      key: 'serialNbr',
    },
    {
      title: 'Customer Num',
      dataIndex: 'customerNbr',
      key: 'customerNbr',
      render: (_, { customer }) => {
        if (customer?.customerNbr === undefined) {
          return <Tag color="default">No Customer ID</Tag>;
        }
        return <span>{customer?.customerNbr}</span>;
      },
    },
    {
      title: 'Contract',
      dataIndex: 'contract',
      key: 'contract',
      render: (text, record) => {
        if (record?.contracts?.length > 0) {
          return (
            <Button
              type="primary"
              size="small"
              onClick={() =>
                openViewContracts(
                  record?.contracts!,
                  record.id!,
                  record.customer?.id!
                )
              }
            >
              View Contracts
            </Button>
          );
        }
        return <Tag color="volcano">No Contract</Tag>;
      },
    },
    isCustomer
      ? {
          title: 'NCR Site',
          dataIndex: 'name',
          key: 'name',
          render: (_, record: AssetType) => {
            if (record?.site === undefined) {
              return <Tag color="default">No Site Available</Tag>;
            }
            if (record?.site && record?.site?.name === undefined) {
              return (
                <Button
                  color="default"
                  type="link"
                  size="small"
                  onClick={() =>
                    viewSingleSite(record?.customer?.id!, record?.site?.id!)
                  }
                >
                  {'SITE ID '.concat(record.site?.ncrSiteId)}
                </Button>
              );
            }
            return (
              <Button
                type="link"
                size="small"
                onClick={() =>
                  viewSingleSite(record?.customer?.id!, record?.site?.id!)
                }
              >
                {record.site?.name}
              </Button>
            );
          },
        }
      : {
          title: 'NCR Site ID',
          dataIndex: 'ncrSiteId',
          key: 'ncrSiteId',
          render: (_, record: AssetType) => {
            if (record?.site?.ncrSiteId === undefined) {
              return <Tag color="default">No Site ID</Tag>;
            }
            return <span>{record.site?.ncrSiteId}</span>;
          },
        },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (text, record: AssetType) => {
        let color = 'green';
        text = 'Other';
        if (record.status === 'Initialized') {
          color = 'green';
          text = 'Initialized';
        }
        if (record.status === 'Updated') {
          color = 'blue';
          text = 'Updated';
        }
        return <Tag color={color}>{text}</Tag>;
      },
    },
    {
      title: '',
      key: 'action',
      render: (_, record: AssetType) => {
        if (isCustomer || viewCustomer) {
          return (
            <Space size="middle">
              <Button
                size="small"
                type="primary"
                onClick={() => tableAction(record.id!, record.customer.id!)}
              >
                View
              </Button>
            </Space>
          );
        }
        return (
          <Space size="middle">
            <Button
              size="small"
              type="primary"
              onClick={() => navigate(`/ncr/assets/${record.id}`)}
            >
              View
            </Button>
          </Space>
        );
      },
    },
  ];

  const setSearchTerms = (value: string) => {
    setSearchFieldSub(value);
    if (searchField === 'status') {
      setFilters(`${searchField}=${value}`);
    } else {
      setFilters(``);
    }
  };

  const setMainSearchTerms = (value: string) => {
    if (
      value === 'actionRequired=true' ||
      value === 'autoUpdated=true' ||
      value === 'requireContract=true'
    ) {
      setFilters(value);
    }
    setSearchField(value);
    setSelectSearchTerms(value);
    setIsSubField(false);
    setSearchFieldSub('');
  };

  useEffect(() => {
    if (
      searchField === 'status' ||
      searchField === 'actionRequired=true' ||
      searchField === 'autoUpdated=true' ||
      searchField === 'requireContract=true'
    ) {
      setIsSubField(true);
      setSearchFieldSub('Select Field');
      setDisableSearch(true);
      setSearchValue('');
    } else {
      setIsSubField(false);
      setSearchFieldSub('Select Field');
      setSelectSearchTerms('');
      setDisableSearch(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchField]);

  useEffect(() => {
    setIsSubField(false);
    setSearchFieldSub('Select Field');
    setSearchField('Select Field');
    setSelectSearchTerms('');
    setDisableSearch(false);
    queryClient.invalidateQueries('assets');
  }, [tableFilter]);

  useEffect(() => {
    queryClient.invalidateQueries('assets');
  }, [filters]);

  const { data: assets, isLoading } = useQuery(
    [
      'assets',
      page,
      pageSize,
      filters,
      tableFilter,
      clientId,
      sessionStorage.getItem('customer'),
    ],
    () =>
      AssetService.getAssets(
        page,
        pageSize,
        filters || tableFilter,
        sessionStorage.getItem('customer')!,
        isCustomer
      )
  );

  const handleChange = (pagination: any) => {
    setPage(pagination.current - 1);
    setPageSize(pagination.pageSize);
  };

  const handleSearch = (e: any) => {
    setPage(0);
    setPageSize(10);
    const encodedValue = encodeURIComponent(e.trim());
    setFilters(`${searchField}=${encodedValue}`);
  };

  const getAssetView = () => {
    if (isCustomer) {
      sessionStorage.setItem('customer', actionAssetID.customerId);
      navigate(
        `/ncr/customers/${actionAssetID.customerId}/assets/${actionAssetID.AssetId}`
      );
    } else {
      navigate(`${actionAssetID.AssetId}`);
    }
  };

  const selectBefore = (
    <div>
      <Select
        defaultValue="Select Field"
        placeholder="Select Field"
        value={searchField}
        onChange={(e) => setMainSearchTerms(e)}
        style={{ width: 200 }}
        getPopupContainer={(trigger) => trigger.parentElement}
      >
        {searchFields?.map((item) => {
          if (isCustomer && item.id === 'customerNbr') {
            return null;
          }
          return (
            <Option key={item.id} value={item.id}>
              {item.value}
            </Option>
          );
        })}
      </Select>
      {searchField === 'status' && (
        <>
          <Select
            defaultValue="Select Field"
            placeholder="Select Field"
            value={searchFieldSub}
            onChange={(e) => setSearchTerms(e)}
            style={{ width: 200 }}
            getPopupContainer={(trigger) => trigger.parentElement}
          >
            {dropdownItems?.map((item) => {
              return (
                <Option key={item.id} value={item.id}>
                  {item.value}
                </Option>
              );
            })}
          </Select>
        </>
      )}
      {searchField === 'status' ||
      searchField === 'autoUpdated=true' ||
      searchField === 'requireContract=true' ||
      searchField === 'actionRequired=true' ? (
        <Button
          type="primary"
          size="small"
          className="clear-button"
          onClick={() => {
            setSearchValue('');
            setFilters('');
            setSearchField('Select Field');
            setSearchFieldSub('Select Field');
            setDisableSearch(false);
            setPage(0);
            setPageSize(10);
          }}
        >
          Clear Filter
          <CloseCircleOutlined />
        </Button>
      ) : null}
    </div>
  );

  return (
    <Row>
      <Col span={24}>
        <Card title={tableTitle !== '' ? tableTitle : ''} className="h-full">
          <div className="search-bar">
            <Search
              addonBefore={selectBefore}
              placeholder="Search"
              enterButton="Search"
              className={`${disableSearch ? 'disabled' : ''}`}
              disabled={disableSearch}
              size="large"
              allowClear
              onSearch={(e) => handleSearch(e)}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </div>
          <br />
          <Table
            columns={
              columnParams || isCustomer
                ? columns.filter((col) => col.key !== 'customerNbr')
                : columns
            }
            rowKey={(record) => record.id!}
            dataSource={assets?.data}
            loading={isLoading}
            onChange={handleChange}
            locale={{
              emptyText: <Empty description="No assets" />,
            }}
            pagination={{
              defaultPageSize: 10,
              current: page + 1,
              pageSize,
              total: Number(assets?.headers['x-total-count']),
              pageSizeOptions: ['10', '20', '50', '100'],
              showSizeChanger: true,
              hideOnSinglePage:
                Number(assets?.headers['x-total-count']) <= pageSize,
            }}
          />
        </Card>
      </Col>
      <Modal
        title={`Action Required - Asset Item Instance Number: ${singleAsset?.itemInstance}`}
        open={openActionModal}
        onCancel={() => setOpenActionModal(false)}
        width="80%"
        footer={[
          <>
            <Button
              key="back"
              size="large"
              onClick={() => setOpenActionModal(false)}
            >
              Close
            </Button>
            <Button
              onClick={() => getAssetView()}
              size="large"
              type="primary"
              disabled={isLoading}
            >
              View
            </Button>
          </>,
        ]}
      >
        <AssetActions
          assetID={singleAsset.id!}
          singleAsset={singleAsset!}
          isLoading={singleAssetLoading}
        />
      </Modal>
      {/* <Modal */}
      {/*  title={`Asset: Serial Number ${asset?.serial}`} */}
      {/*  open={openApprovalModal} */}
      {/*  onCancel={() => setOpenApprovalModal(false)} */}
      {/*  width="80%" */}
      {/*  footer={[ */}
      {/*    <> */}
      {/*      <Button */}
      {/*        key="back" */}
      {/*        size="large" */}
      {/*        onClick={() => setOpenApprovalModal(false)} */}
      {/*      > */}
      {/*        Close */}
      {/*      </Button> */}

      {/*      <Button onClick={() => getAssetView()} size="large" type="primary"> */}
      {/*        View */}
      {/*      </Button> */}
      {/*    </>, */}
      {/*  ]} */}
      {/* > */}
      {/*  <AssetPendingTasks assetId={asset.ID!} /> */}
      {/* </Modal> */}
      <Modal
        title="Asset Contracts"
        open={openContractsModal}
        onCancel={() => closeContractsModal()}
        width="80%"
        footer={[
          <Button key="back" size="large" onClick={() => closeContractsModal()}>
            Close
          </Button>,
        ]}
      >
        <NCRAssetsContractsTable
          assetId={assetId}
          tableData={assetContracts}
          isCustomer={isCustomer}
          customerId={contractCustomer}
          searchFields={[
            { id: 'status', value: 'Status' },
            { id: 'customerNbr', value: 'Customer Number' },
          ]}
        />
      </Modal>
    </Row>
  );
};

export default AssetTable;
