/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';

import {
  Button,
  Card,
  Modal,
  notification,
  Tabs,
  TabsProps,
  Upload,
} from 'antd';

import { resetDataSerivce } from '../../APIServices/ResetService';
import { NotificationType } from '../../utils/types';
import CSVUploads from '../CSVManagement/CSVUploads/CSVUploads';
import Downloader from '../CSVManagement/Downloader/Downloader';
import Reporting from '../CSVManagement/Reporting/Reporting';
import SliamUploader from '../CSVManagement/SliamUploader/SliamUploader';

const { Dragger } = Upload;

const CardHeader = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [resetLoader, setResetLoader] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (
    type: NotificationType,
    title: string,
    description: string
  ) => {
    api[type]({
      message: title,
      description,
    });
  };

  const resetHandler = () => {
    setIsModalOpen(true);
  };

  const handleConfirm = async () => {
    try {
      setResetLoader(true);
      const response = await resetDataSerivce();
      if (response.status === 200) {
        openNotificationWithIcon(
          'success',
          'Success',
          'Reset data successfully, page will reload in 3 seconds...'
        );
        setResetLoader(false);
        setIsModalOpen(false);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
    } catch (e) {
      openNotificationWithIcon(
        'error',
        'Error',
        'Something went wrong, please try again'
      );
      setIsModalOpen(false);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {contextHolder}
      <Modal
        title="Reset Data"
        open={isModalOpen}
        onOk={handleConfirm}
        onCancel={handleCancel}
        okText="Confirm"
        okButtonProps={{ loading: resetLoader }}
      >
        <p>
          Please click on <b>Confirm</b> to reset data <br />
          (This action is irreversible once confirmed) To abort click{' '}
          <b>Cancel</b>
        </p>
      </Modal>

      <div className="flex justify-between">
        <h3 className="self-center mb-0">Data Management</h3>
        <Button
          type="default"
          size="large"
          className="self-center"
          danger
          onClick={resetHandler}
        >
          Reset Data
        </Button>
      </div>
    </>
  );
};

const DataManagement = () => {
  const [current, setCurrent] = React.useState<string>('sliamUploader');

  const items: TabsProps['items'] = [
    {
      label: 'Uploader',
      key: 'sliamUploader',
      children: <SliamUploader tab={current} />,
    },

    {
      label: 'Upload History',
      key: 'upload-history',
      children: <CSVUploads tab={current} />,
    },
    {
      label: 'Downloads',
      key: 'downloader',
      children: <Downloader tab={current} />,
    },
    {
      label: 'Reports',
      key: 'reports',
      children: <Reporting tab={current} />,
    },
  ];

  return (
    <Card title={<CardHeader />}>
      <Tabs
        defaultActiveKey="1"
        size="small"
        style={{ marginBottom: 32 }}
        items={items}
        type="line"
        onChange={(key) => setCurrent(key)}
      />
    </Card>
  );
};

export default DataManagement;
